import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from 'store/store'

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    isDrawerOpen: false,
  },
  reducers: {
    setIsDrawerOpen: (state, action) => {
      state.isDrawerOpen = action.payload
    },
  },
})

// Actions
export const layoutActions = {
  setIsDrawerOpen: layoutSlice.actions.setIsDrawerOpen,
}

// Selectors
export const selectIsDrawerOpen = (state: RootState) => state.appLayout?.isDrawerOpen

// Reducer
export default layoutSlice.reducer
